import { Helmet } from "react-helmet";
import { MaterialReactTable } from "material-react-table";
import {
  NotificationApi,
  baseApiUrl,
  NotificationColumn,
  objectTypeList
} from "./const";
import React, { useState, useEffect } from "react";
import {
  IconButton, Button
} from "@mui/material";
import PrintIcon from '@mui/icons-material/Print';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Menu } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
import { WScalling } from "./Apicalling";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logoImage from '../img/logo1.png';

var filteredObjects = [];
let filterList;
let filterLists;
let searchObjectType = null;
let searchObjectName = null;
let searchObjectFieldReminder = null;
let searchObjectDate = null;
let searchStartExpireDate = null;
let searchEndExpireDate = null;

const Notification = () => {
  const { t } = useTranslation();
  const [userDetail] = useState(JSON.parse(localStorage.getItem('userdetail')));
  const [companyid] = useState({ company_id: userDetail.company_id });
  let [notiColumnList, setnotiColumnList] = useState(() => []);
  let [notiList, setnotiList] = useState(() => []);
  const [filterObjList, setfilterObjList] = useState(() => []);

  let [SearchObjType, setSearchObjType] = useState('');
  let [searchObjectName, setsearchObjectName] = useState('');
  let [Searchdate, setSearchdate] = useState('');

  let [searchStartTime, setsearchStartTime] = useState('');
  let [searchEndTime, setsearchEndTime] = useState('');
  let [searchFiledRemind, setsearchFiledRemind] = useState('');

  let [pagetitle] = useState(() => t('Lang.Form360') + ' - ' + t('Lang.Notification'));
  let [Currentdate] = useState(`${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`);

  const [ sortdirection, setSortDirection ] = useState("asc");
  const [ sortcolumn, setSortColumn ] = useState("");

  useEffect(() => {
    WScalling(baseApiUrl + NotificationColumn, "post", companyid, userDetail.token).then((response) => {
      setColumnList(response.data);
      filterLists = response.data;
    });
  }, []);


  const formatdate = (dates) => {
    return dates.split('.')[2] + '-' + dates.split('.')[1] + '-' + dates.split('.')[0]
  }

  const formatDateEurope = (dateString) => {
    let [completeDate, completeTime] = dateString.split(' ');

    if (!completeTime) {
      const [year, month, day] = completeDate.split('-');
      return `${day}.${month}.${year}`;
    }

    let [year, month, day] = completeDate.split('-');
    let [hours, minutes, seconds] = completeTime.split(':');

    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;   
  };

  const setColumnList = (data) => {

    const updatedWidths = {};
  
    for (let i = 0; i < data.length; i++) {
      const field = data[i];
  
      if (data[i].type === 'date') {
        field.Cell = ({ renderedCellValue, row }) => (
          <div>
            <label>{(renderedCellValue === '01.01.1970' || renderedCellValue === '' || renderedCellValue === null) ? '' : renderedCellValue}</label>
          </div>
        )
      }
  
      if (data[i].accessorKey === 'value') {
        data[i].header = t('Lang.' + data[i].header)
  
        field.Cell = ({ renderedCellValue, row }) => (
          <div>
            <label className={
              renderedCellValue !== '' && renderedCellValue !== null && new Date(renderedCellValue.split(".")[2] + '-' + renderedCellValue.split(".")[1] + '-' + renderedCellValue.split(".")[0]).getTime() < new Date(Currentdate).getTime()
                ?
                'text_danger' :
                'blackclass'
            } 
            >{(renderedCellValue === '01.01.1970' || renderedCellValue === '1970-01-01' || renderedCellValue === '' || renderedCellValue === null) ? '' : renderedCellValue.split("-")[2] + '.' + renderedCellValue.split("-")[1] + '.' + renderedCellValue.split("-")[0]}</label>

          </div>
        )
      }
  
      else if (data[i].accessorKey === 'date_time') {
        continue;
      }
  
      else if (data[i].accessorKey === 'aff_field_remind') {
        data[i].header = t('Lang.' + data[i].header)
      }
  
      else if (data[i].accessorKey === 'aff_field_remind_hours') {
        data[i].header = t('Lang.' + data[i].header)
      }
  
      else if (data[i].accessorKey === 'remind_hours_value') {
        data[i].header = t('Lang.' + data[i].header)
      }
  
      else if (data[i].accessorKey === 'object') {
        data[i].header = t('Lang.' + data[i].header)
      }
  
      else if (data[i].accessorKey === 'object_type') {
        data[i].header = t('Lang.' + data[i].header)
  
        field.Cell = ({ renderedCellValue, row }) => (
          <div style={{ textAlign: "center" }}>
            <div className="radio-common">
              <span className={"img_common_line mx-auto " + row.original.object_type}> </span>
            </div>
            <label className="text-center w-100">{t('Lang.' + row.original.object_type)}</label>
          </div>
        )
      }
    }
  
    // re-arrange the data array based on accessorKey
    const reorderKeys = ['value', 'aff_field_remind_hours', 'remind_hours_value'];
    const reorderedData = [
      ...reorderKeys.map(key => data.find(d => d.accessorKey === key)).filter(Boolean),
      ...data.filter(d => !reorderKeys.includes(d.accessorKey) && d.accessorKey !== 'date_time')
    ];
  
  
    WScalling(baseApiUrl + NotificationApi, "post", companyid, userDetail.token).then((response) => {
      filterList = filteredObjects = response.data
  
      // let originalData = response.data;
    
      // Check if any object has a non-null value for aff_field_remind_hours
      let includeAffFieldRemindHours = filterList.some(item => item.aff_field_remind_hours !== null);
      let includeRemindHoursValue = filterList.some(item => item.remind_hours_value !== null);
      let includeValue = filterList.some(item => item.value !== null);
      
      // Filtered response data based on the presence of non-null aff_field_remind_hours
      let filteredResponseData = filterList.map(item => {
        let newItem = { ...item };
        delete newItem.date_time; // Remove date_time key
        if (!includeAffFieldRemindHours) {
          delete newItem.aff_field_remind_hours;
        }
        if (!includeRemindHoursValue) {
          delete newItem.remind_hours_value;
        }
        if (!includeValue) {
          delete newItem.value;
        }
        return newItem;
      });
  
      // Update the column list based on the presence of data
      let updatedColumns = reorderedData.filter(column => {
        if (column.accessorKey === 'aff_field_remind_hours' && !includeAffFieldRemindHours) {
          return false;
        }
        if (column.accessorKey === 'remind_hours_value' && !includeRemindHoursValue) {
          return false;
        }
        if (column.accessorKey === 'value' && !includeValue) {
          return false;
        }
        return true;
      });
      
      setnotiColumnList(updatedColumns);
  
      let dayhours = []
  
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].value === '01.01.1970' && response.data[i].remind_hours_value === null) {
        } else {
          dayhours.push(response.data[i])
        }
      }
      
      setfilterObjList(dayhours);
      setnotiList(dayhours);
    });
  }

  const SearchByType = (objval) => {
    searchObjectType = objval;
    FilterNotification()
  }

  const SearchByObjectName = (val) => {
    searchObjectName = val;
    FilterNotification()
  }

  const SearchByFieldReminder = (val) => {
    searchObjectFieldReminder = val;
    FilterNotification()
  }

  const SearchByObjectDate = (val) => {
    searchObjectDate = val;
    FilterNotification()
  }

  const SearchByStartExpireDate = (val) => {
    searchStartExpireDate = val;
    FilterNotification()
  }

  const SearchByEndExpireDate = (val) => {
    searchEndExpireDate = val;
    FilterNotification()
  }

  const Datecheck = (dateval) => {
    if (isNaN(dateval.get('date')) || isNaN(dateval.get('month')) || isNaN(dateval.get('year'))) {
      return null
    }
    else {
      return ("0" + dateval.get('date')).slice(-2) + '.' + ("0" + (dateval.get('month') + 1)).slice(-2) + '.' + dateval.get('year');
    }
  }


  const FilterNotification = () => {
    filterList = filteredObjects;

    if (searchObjectType !== null && searchObjectType !== t('Lang.Select object type')) {

      filterList = filterList.filter(
        (type) => type.object_type.toLowerCase() === searchObjectType.toLowerCase()
      );
    }

    if (searchObjectName !== null) {
      filterList = filterList.filter((type) => {
        if (type.object.toLowerCase().includes(searchObjectName.toLowerCase())) {
          return true
        }
        else {
          return false
        }
      });
    }

    if (searchObjectDate !== null) {
      filterList = filterList.filter((type) => {
        var typeDateFormat = type.value.split('-')[2] + '.' + type.value.split('-')[1] + '.' + type.value.split('-')[0];
        if (type.value && typeDateFormat.toLowerCase().includes(searchObjectDate.toLowerCase())) {
          return true
        }
        else {
          return false
        }
      });
    }

    if (searchObjectFieldReminder !== null && searchObjectFieldReminder !== t('Lang.Filter service field...')) {
      filterList = filterList.filter((type) => {
        if (type.aff_field_remind && type.aff_field_remind.toLowerCase().includes(searchObjectFieldReminder.toLowerCase())) {
          return true
        }
        else {
          return false
        }
      });
    }

    if (searchStartExpireDate !== '' && searchEndExpireDate !== '' && searchStartExpireDate !== null && searchEndExpireDate !== null) {
      filterList = filterList.filter((type) => {
        if (type.time_of_creation >= searchStartExpireDate && type.time_of_creation <= searchEndExpireDate) {
          return true
        }
        else {
          return false
        }
      });
    }
    setnotiList(filterList)
  }
  const handleSortList = ( column ) => {
    if ( sortcolumn === column ) {
        setSortDirection(( previousdirection ) => {
            if ( previousdirection === "asc" ) return "desc" ;
            if ( previousdirection === "desc" ) return "" ;
            return "asc" ;
        })
    } else {
        setSortColumn ( column );
        setSortDirection ( "asc" ) ;
    }
  };

  function compareValues(valueA, valueB) {
    const dateRegex = /^\d{2}\.\d{2}\.\d{4}$/; // Regex to match dd.mm.yyyy format

    // Check if both values match the date format
    if (dateRegex.test(valueA) && dateRegex.test(valueB)) {
      const datePartsA = valueA.split('.').map(part => parseInt(part)); // Splitting and parsing date parts
      const datePartsB = valueB.split('.').map(part => parseInt(part)); // Splitting and parsing date parts

      // Comparing year
      if (datePartsA[2] !== datePartsB[2]) {
          return datePartsA[2] - datePartsB[2];
      }

      // Comparing month
      if (datePartsA[1] !== datePartsB[1]) {
          return datePartsA[1] - datePartsB[1];
      }

      // Comparing day
      return datePartsA[0] - datePartsB[0];
    } else {
      // If not both dates, return string comparison
      return String(valueA).localeCompare(String(valueB), undefined, { numeric: true });
    }
  }

  const sortData = [...notiList].sort((a, b) => {
    const columnA = sortcolumn ? a[ sortcolumn ] : "" ;
    const columnB = sortcolumn ? b[ sortcolumn ] : "" ;

    if ( sortdirection === "asc" ) {
        return compareValues ( columnA, columnB )
    } else if ( sortdirection === "desc" ) {
        return compareValues ( columnB, columnA)
    } else {
        return 0;
    }
  });
  // console.log('sordData',sortData);

  const generatePDF = () => {
    const pdf = new jsPDF({
      encoding: 'UTF-8',
      orientation: 'landscape', // width of the page is greater than its height.
      unit: 'mm',
      format: [297, 210], // A4 size page
    });

     // Set Title text in left-top corner
    const titleText = t('Lang.Notification Report');
    const titleWidth = pdf.getStringUnitWidth(titleText) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
    const titleXPos = 14; // Left alignment
    const titleYPos = 22; // Top alignment
    const titleFontSize = 35; // Increased font size for the title

    pdf.setFontSize(titleFontSize);
    pdf.text(titleText, titleXPos, titleYPos);

    // Logo size in pixels
    const logoWidthInPixels = 204;
    const logoHeightInPixels = 40;

    // Convert pixel size to millimeters
    const pixelToMm = 0.264583;
    const logoWidth = logoWidthInPixels * pixelToMm;
    const logoHeight = logoHeightInPixels * pixelToMm;

    // Margin in millimeters
    const logoMargin = 14;

    // Replace 'logoImage' with the correct path to your logo image
    const logoImageDataURL = logoImage;

    // Add logo to the top right corner
    pdf.addImage(
      logoImageDataURL,
      'PNG',
      pdf.internal.pageSize.getWidth() - logoWidth - logoMargin,
      logoMargin,
      logoWidth,
      logoHeight
    );
  
    // Organize filter fields in 2 rows and 3 columns
    const filterFields = [
      { label: t('Lang.Object Type') + ':', value: SearchObjType ? t('Lang.' + SearchObjType) : SearchObjType},
      { label: t('Lang.Object Name') + ':', value: searchObjectName },
      { label: t('Lang.Date') + ':', value: Searchdate },
      { label: t('Lang.Start Time') + ':', value: searchStartTime },
      { label: t('Lang.End Time') + ':', value: searchEndTime },
      { label: t('Lang.Field Reminder') + ':', value: searchFiledRemind },
    ];
  
    // Set the starting positions for the rows and columns
    let xPos = 14;
    let yPos = 35;
    const colWidth = 60; // Adjust the width of each column
    const rowHeight = 7; // Adjust the height of each row
    const fontSize = 10;

    // Define the order in which the labels should appear in each row 
    const row1labels = [ t('Lang.Object Type') + ':' , t('Lang.Object Name') + ':' ];
    const row2labels = [ t('Lang.Date') + ':', t('Lang.Start Time') + ':', t('Lang.End Time') + ':' ];
    const row3labels = [ t('Lang.Field Reminder') + ':' ];

    // Loop through the filter fields and position them in the PDF
    const positionFields = (labels) => {
      for (const label of labels) {
        const field = filterFields.find((item) => item.label === label);

        if (field) {
          const labelText = `${field.label} ${field.value}`;
          pdf.setFontSize(fontSize);
          pdf.text(labelText, xPos, yPos);

          xPos += colWidth;
        }
      }
    }
    
    positionFields(row1labels);
    xPos = 14;
    yPos += rowHeight;

    positionFields(row2labels);
    xPos = 14;
    yPos += rowHeight;

    positionFields(row3labels);

    //pdf.setFont('helvetica', 'normal');
    pdf.setFontSize(6);

    const columns = notiColumnList
    .filter(head => head.accessorKey !== 'date_time') // Remove 'date_time' column
    .map(head => head.header);

    const cols = notiColumnList
    .filter(hed => hed.accessorKey !== 'date_time') // Remove 'date_time' accessor
    .map(hed => hed.accessorKey);
    
    const formattedColumns = columns.map(column => ({
      content: column.split('_').join('\n'),
      styles: { autoSize: true, fontSize: 6, halign: 'center', fillColor: '#2c82d8' }, // background change #2c82d8
    }));
    
    const bodyData = sortData.map(notifi => cols.map(data => {
      
      if (data === 'object_type') {
        return t('Lang.' + notifi[data]);
      } else if (notifi[data] === '01.01.1970' || notifi[data] === '' || notifi[data] === null) {
        return ' ';
      } else if (data === 'date_time'){
        // Format the date and time using formatDateEurope function
        return formatDateEurope(notifi[data]);
      } else if (data === 'value') {  
        // Format date_value in dd.mm.yyyy format
        return formatDateEurope(notifi[data]);  
      } else {
        return notifi[data];
      }
    }));
    // Set font size for body cells
    const fontSizeBody = 6;
    const formattedBodyData = bodyData.map(row =>
      row.map(cell => ({ content: cell || 'N/A', styles: { fontSize: fontSizeBody, halign: 'center' } }))
    );
    
    // Track the total pages dynamically
    let totalPages = 1;

    pdf.autoTable({
      encoding: 'UTF-8',
      head: [formattedColumns],
      body: formattedBodyData,
      startY: 55,
      //theme: 'grid',
      // margin: { top: 35 }, // add extra margin on table 
    
      didDrawPage: function (data) {
        // Update the total pages after each page is drawn
        totalPages = pdf.internal.getNumberOfPages();
      },
    });
    
    // Add pagination text on each page after the table
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(10);
      pdf.text(`${t('Lang.Page')} ${i} ${t('Lang.of')} ${totalPages}`, pdf.internal.pageSize.getWidth() / 2, pdf.internal.pageSize.getHeight() - 10, { align: 'center' });
    }
    pdf.save( 'Notification report.pdf' );
  };

  return (
    <>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>

      <div className="col-sm p-3 min-vh-100 main-listing position-relative pagination_top">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-3">
            <li className="breadcrumb-item active" aria-current="page"> {t('Lang.Notification')} </li>
          </ol>
        </nav>

        <div className="configure-section setting-section col-sm p-0">

          <MaterialReactTable
            enableBottomToolbar={false}
            localization={{
              noRecordsToDisplay: t('Lang.No records to display'),
              noResultsFound: t('No results found'),
            }}

            muiTableBodyProps={{
              sx: {
                //stripe the rows, make odd rows a darker color
                '& td:nth-of-type(odd)': {
                  backgroundColor: '#f5f5f5',
                },
                "& tr:nth-of-type(odd)": {
                  backgroundColor: "#f5f5f5",
                },
              },
            }}

            muiTableBodyCellProps={{
              sx: {

                backgroundColor: '#f5f5f5',
                borderRight: '2px solid #e0e0e0', //add a border between columns
              },
            }}

            muiTableBodyProps={{
              sx: {
                //stripe the rows, make odd rows a darker color
                "& tr:nth-of-type(odd)": {
                  backgroundColor: "#f5f5f5",
                },
              },
            }}

            muiTableBodyCellProps={{
              sx: {
                border: "0 solid #e0e0e0", //add a border between columns
              },
            }}

            columns={notiColumnList.map((column) => ({
              ...column,
              header: (
                  <div onClick={() => handleSortList(column.accessorKey)} className="configure-section notify-head" style={{cursor: 'pointer'}}>
                      {sortcolumn === column.accessorKey && sortdirection === 'asc' && (
                          <span > <ArrowUpwardIcon className="Mui-active"/> </span> /* Display arrow pointing upwards for ascending order */
                      )}
                      {sortcolumn === column.accessorKey && sortdirection === 'desc' && (
                          <span> <ArrowDownwardIcon className="Mui-active"/> </span> /* Display arrow pointing downwards for descending order */
                      )}
                      {sortcolumn === column.accessorKey && sortdirection === '' && (
                          <span> <ArrowUpwardIcon/> </span> /* Display arrow pointing upwards for unsorted order */
                      )}
                      {sortcolumn !== column.accessorKey && ( 
                          <span> <ArrowUpwardIcon/> </span> /* Display default arrow pointing upwards if not currently sorted */
                      )}
                      {column.header} {/* Display column header text */}
                  </div>
              ),
          }))}
            //data={notiList}
            data={sortData}
            sortDescFirst={false}
            editingMode="modal" //default
            enableColumnOrdering={false}
            enableSorting={false}
            enableColumnFilters={false}
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            enableHiding={false}
            initialState={{
              density: "compact",
              showGlobalFilter: true,
              columnVisibility: { id: false, order: false, Move: false },
            }}

            disableColumnResizing
            enableGlobalFilter={false}
            enableRowActions={false}
            enablePagination={false}
            muiSearchTextFieldProps={{
              placeholder: "Search...",
            }}

            options={{
              cellstyle: { whiteSpace: 'nowrap' },
              rowStyle: {
                overflowWrap: 'break-word'
              }
            }}

            icons={{
              DragHandleIcon: () => <IconButton> <Menu /> </IconButton>,
              MoreVertIcon: () => null
            }}

            renderTopToolbarCustomActions={() => (
              <>

                <div className="notification_page py-lg-2 px-0 d-flex flex-wrap justify-content-xl-between justify-content-lg-start w-100 gap-lg-2 gap-2 pt-2">

                  <div className="left d-flex flex-wrap justify-content-md-start w-100">

                    <div className="d-flex flex-wrap gap-lg-3 gap-2 align-items-lg-center align-items-start w-100 md-grid-cols-2 sm-grid-cols-2">

                      <div className="form_search w-md-50">
                        <select
                          className="form-control select-dropdown"
                          id="type"
                          name="type"
                          value={SearchObjType}
                          type="text"
                          onChange={(e) => {
                            setSearchObjType(e.target.value)
                            SearchByType(e.target.value)
                          }}
                          placeholder="Select Type"
                          required
                        >
                          <option defaultValue>{t('Lang.Select object type')}</option>
                          {objectTypeList
                          .sort((a, b) => a.option.localeCompare(b.option))
                          .map((e, key) => {
                            return <option key={key} value={e.option}>{t('Lang.' + e.option)}</option>;
                          })}
                        </select>
                      </div>

                      <form className="form_search w-md-50">
                        <input
                          value={searchObjectName}
                          className="form-control mr-sm-2"
                          type="search"
                          onChange={(e) => {
                            setsearchObjectName(e.target.value)
                            SearchByObjectName(e.target.value)
                          }}
                          placeholder={t('Lang.Search object name..')}
                          aria-label="Search">
                        </input>
                        <svg viewBox="-2 -2 103 103"><path strokeWidth="3" d="M78.11,69.76c-1.21-1.12-2.69-1.5-3.29-.85s-2.1.27-3.32-.83L70.3,67a3.75,3.75,0,0,1-.64-4.56S78,49,78,39A39,39,0,1,0,39,78c9,0,21.17-6.67,21.17-6.67A4.51,4.51,0,0,1,65,71.9l1.61,1.46c1.22,1.11,1.82,2.44,1.34,3s.11,1.87,1.33,3L90.89,99.24a3,3,0,0,0,4.24-.17l4.74-5.14a3,3,0,0,0-.17-4.24ZM39,71A32,32,0,1,1,71,39,32,32,0,0,1,39,71Z" /></svg>
                      </form>

                      <div className="form_search w-md-100 d-flex flex-wrap gap-sm-3 gap-2">

                        <div className="date_picker w-md-50">

                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                            <DemoContainer components={['DatePicker']}>
                              <DatePicker
                                onChange={(newValue) => {
                                  if (Datecheck(newValue) !== null) {
                                    setSearchdate(Datecheck(newValue))
                                    SearchByObjectDate(Datecheck(newValue))
                                  }
                                }}
                                slotProps={{ textField: { size: 'small', placeholder: t('Lang.Select date'), label: '' } }}
                                format='DD.MM.YYYY'
                                value={dayjs(formatdate(Searchdate))}
                              // className="form-control form-control w-100"
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          {/* <img src={DateIcon} alt="date" className="icon-date" /> */}
                        </div>

                        <input
                          value={searchStartTime}
                          className="form-control position-relative"
                          type="time"
                          name="name"
                          required
                          onChange={(e) => {
                            setsearchStartTime(e.target.value)
                            SearchByStartExpireDate(e.target.value)
                          }}
                        />

                        <span className="d-flex align-items-center line"> - </span>

                        <input
                          value={searchEndTime}
                          className="form-control position-relative"
                          type="time"
                          name="name"
                          required
                          onChange={(e) => {
                            setsearchEndTime(e.target.value)
                            SearchByEndExpireDate(e.target.value)
                          }}
                        />
                      </div>

                      <form className="form_search w-md-50">
                         <select
                            className="form-control select-dropdown"
                            id="type"
                            name="type"
                            value={searchFiledRemind}
                            type="text"
                            onChange={(e) => {
                              setsearchFiledRemind(e.target.value)
                              SearchByFieldReminder(e.target.value)                         
                            }}
                            placeholder={t('Lang.Filter service field...')}
                            required
                          >
                            <option defaultValue>{t('Lang.Filter service field...')}</option>
                            {Array.from(new Set(filteredObjects
                                .filter(value => value.aff_field_remind && value.aff_field_remind.trim() !== '') // Check for null and trim
                                .map(value => value.aff_field_remind))) // Map to aff_field_remind values and filter out duplicates
                                // .sort() // Sort in asc order
                                .sort((a, b) => a.localeCompare(b)) // Sort in asc order
                                .map((affFieldRemind, index) => (
                                <option key={index} value={affFieldRemind}>
                                  {affFieldRemind}
                                </option>
                              ))
                            }
                          </select>
                      </form>

                      <Button
                        color="secondary"
                        className="add-new-field mx-0 ms-xl-auto ms_lg_auto"
                        onClick={() => {
                          setSearchObjType('')
                          setsearchObjectName('')
                          setSearchdate('')
                          setsearchStartTime('')
                          setsearchEndTime('')
                          setsearchFiledRemind('')

                          searchObjectType = null;
                          searchObjectName = null;
                          searchObjectFieldReminder = null;
                          searchObjectDate = null;
                          searchStartExpireDate = null;
                          searchEndExpireDate = null;

                          setnotiList(filterObjList)
                          window.location.reload();
                        }}
                        variant="contained"
                      >
                        {t('Lang.RESET')}
                      </Button>

                      <Button
                        color="secondary"
                        className="add-new-field mx-0 ms_lg_auto"
                        variant="contained"
                        onClick={generatePDF}
                      >
                      <PrintIcon />
                      </Button>

                    </div>

                  </div>

                </div>

              </>
            )}
          />
        </div>
      </div>
    </>
  );
}

export default Notification;