export const headers = {
  AccessToken:
    "tdtyP1BsWS7qZW3o6pAk5o4YPamsoXcnRE5gBTM6FdakpMMBAwLG9Ab9lNTfWVSNyEPwDi7wFSWq6CQU",
};

export const objectTypeList = [
  {
    'option': 'CAR'
  },
  {
    'option': 'TRUCK'
  },
  {
    'option': 'BUS'
  },
  {
    'option': 'MOTORBIKE'
  },
  {
    'option': 'TRAILER'
  },
  {
    'option': 'TRACTOR'
  },
  {
    'option': 'BOAT'
  },
  {
    'option': 'FORKLIFT'
  },
  {
    'option': 'TRAIN'
  },
  {
    'option': 'EXCAVATOR'
  },
]


export const HistoryColumns = [
  {
    accessorKey: "service_date",
    header: 'Service date',
    enableColumnOrdering: false,
    size: 10,
  },
  {
    accessorKey: "type",
    header: 'Type',
    enableColumnOrdering: false,
    size: 150,
  },
  {
    accessorKey: "title",
    header: 'Title',
    size: 150,
  },
  {
    accessorKey: "cost",
    header: 'Cost',
    enableColumnOrdering: false,
    size: 150,
  },
  {
    accessorKey: "description",
    header: 'Description',
    size: 140,
    enableColumnOrdering: false,
  },
  {
    accessorKey: "workshop",
    header: 'Workshop',
    size: 140,
    enableColumnOrdering: false,

  },
  {
    accessorKey: "created_on",
    header: 'Created on',
    size: 140,
    enableColumnOrdering: false,

  },
  {
    accessorKey: "created_by",
    header: 'Created By',
    size: 80,
    enableColumnOrdering: false,
  },
  {
    accessorKey: "updated_on",
    header: 'Updated on',
    size: 80,
    enableColumnOrdering: false,
  },
];


// Environment Settings
// dev - Development Server
// live - Live Server
// local - Local Server

let Environment = 'live'; 

export const baseApiUrl = Environment === 'live' 
? "https://api.form360.app/api/v1/" :
(
  Environment === 'dev' ? 
  "https://dev.service360.laraveldev.work/api/v1/" 
  : "https://service-reminder-api.test/api/v1/"
);

export const AllFields = "fields";

export const AllTaskFieldAPI = "tasks";

export const CreateFieldAPI = "field/create";

export const TaskCreateAPI = "task/create";

export const DeleteFieldAPI = "field/delete/";

export const DeleteTaskdAPI = "task/delete/";

export const EditTaskAPI = "task/update/";

export const EditFieldAPI = "field/update/";

export const SortFieldAPI = "field/sort";

export const SortTaskdAPI = "task/sort";

export const Createobj = "object/create";

export const GetobjFields = "object/getfields";

export const GetobjColumn = "object/getcolumns";

export const GetobjList = "objects";

export const UserList = "users";

export const UserCreate = "user/create";

export const UserLogin = "user/loginascustomer";

export const UserDelete = "user/delete/";

export const UserUpdate = "user/update/";

export const UserSuperAdmin = "user/superadmin";

export const Compuny = "companies";

export const Roles = "roles";

export const GetObjEditField = "object/";

export const ObjUpdate = "object/update/";

export const ObjDelete = "object/";

export const HistoryListApi = "entry/showEntries";

export const GetEntryField = "entry/getentryfields";

export const createEntry = "entry/create";

export const updateEntry = "entry/update/";

export const deleteEntry = "entry/"

export const EditApi = "entry/edit/";

export const NotificationApi = "notifications";

export const NotificationCount = "getnotificationscount";

export const NotificationColumn = "getnotificationscolumn";

export const ForgotPass = "user/forgot-password"

export const ResetPass = "user/reset-password"

export const companies = "companies"

export const createCompany = "company/create";